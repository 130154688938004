<template>
  <div>
    <!-- <b-navbar class="bg-light">
      <b-navbar-nav class="w-100">
        <b-nav-text class="w-100 text-center">文件电子签平台</b-nav-text>
      </b-navbar-nav>
    </b-navbar> -->

    <b-navbar sticky class="bg-light">
      <b-container>
        <b-navbar-nav>
          <b-nav-text>文件数（{{file_path_list.length?fileIndex+1:0}}/{{file_path_list.length}}）</b-nav-text>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="last">上一个</b-nav-item>
          <b-nav-item @click="next">下一个</b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    
    <div class="w-100" v-if="footer">
      <pdf
        v-for="i in numPages"
        :key="i"
        :src="src"
        height="100%"
        width="100%"
        :page='i'
      ></pdf>
    </div>


    <!-- 成功 -->
    <div v-if="sucess" style="text-align:center;">
      <img src="../stactic/image/sucess.png" alt="">
      <div class="res">已完成</div>
    </div>
    <!-- 失败 -->
    <div v-if="error" style="text-align:center;">
      <img src="../stactic/image/error.png" alt="">
      <div class="res">{{error_content}}</div>
    </div>

    <div class="fixed-bottom bg-light py-3">
      <b-container>
        <div v-if="footer">
          <div class="form-check form-check-inline">
            <input :disabled="checkoutDisabled" class="form-check-input" type="checkbox" id="agreeCheckbox" v-model="checkbox">
            <label class="form-check-label" for="agreeCheckbox">已阅读并同意协议内容</label>
          </div>
          <div class="mt-3 text-center">
            <button @click="submit" :disabled="disabled" type="button" class="btn btn-outline-primary">立即签署</button>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-center">
            <button @click="closePage" class="btn">关闭页面</button>
          </div>
        </div>
      </b-container>
    </div>

  </div>
</template>

<script>

// import pdfjsLib from "pdfjs-dist/build/pdf";
// import * as PDFViewer from "pdfjs-dist/web/pdf_viewer";
// import "pdfjs-dist/web/pdf_viewer.css";
// pdfjsLib.GlobalWorkerOptions.workerSrc =
//   "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js";


import pdf from 'vue-pdf'
export default {
  components:{ pdf },
  data () {
    return {
      checkbox:false,
      sucess:false,
      error:false,
      footer:true,
      // 订单编号
      order_no:'',
      error_content:'',
      fileData:null,
      file_path_list:[],
      fileIndex:0,
      checkoutDisabled:true,

      src:null,
      numPages:null,

    }
  },
  created(){
    this.$toasted.show('1.请阅读文件后，进行协议签署；<br>2.请签署法人英文名字（或名字拼音）；<br><br>正在获取签署文件，请耐心等待...',{position:'top-center',})
    const order_no_key = Object.keys(this.$route.query)[0]
    this.order_no = this.$route.query[order_no_key]
    this.getSignatureFileList(this.order_no)
  },
  computed:{
    disabled(){
      if(this.checkbox){
        return false
      }else{
        return true
      }
    },
  },
  watch:{
    fileIndex(n){
      this.pdf(n)
    }
  },
  methods:{
    // 上一个
    last(){
      if(this.fileIndex == 0)return
      this.fileIndex = this.fileIndex -1
    },
    // 下一个
    next(){
      if(this.fileIndex == this.file_path_list.length-1)return
      this.fileIndex = this.fileIndex + 1
    },

    async getSignatureFileList(order_no){
      const res = await this.$axios.get(`/signature/signatureFileList?order_no=${order_no}`)
      // console.log('res',res)
      if(res.data.code==200){
        this.$toasted.clear()
        // 文件展示
        this.fileData = res.data.data.list
        this.fileData.forEach(item=>{
          this.file_path_list.push(item.file_path)
        })

        this.pdf(this.fileIndex)
        this.$toasted.show('获取签署文件成功',{duration:3000,position:'top-center',})
        
        this.checkoutDisabled = false
      }else{
        this.$toasted.clear()
        this.$toasted.show(res.data.msg,{duration:3000,position:'top-center',})
      }
    },
    pdf(link){
      this.src = pdf.createLoadingTask(this.file_path_list[link]);
      this.src.promise.then(pdf => {
        this.numPages = pdf.numPages;
      }).catch(e=>e);
    },
    // 签署
    async submit(){
      this.$router.push({path:'/signature',query:{
        order_no:this.order_no,
        file_list:JSON.stringify(this.fileData)
      }})
    },
    closePage(){
      window.location.href = "about:blank"
    },

  }
}
</script>

<style>
body { background-color: #ffffff!important;}
</style>

<!-- <style scoped>
.app{
  width: 100%;
  max-width: 99999px;
  height: 100%;
  font-size: 26px;
  position: relative;
}
.loading{
  margin-top: 100px;
}
.top{
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  z-index: 99;
}

.title{
  margin: 0 auto;
  text-align: center;
}
.span{
  color: blue;
  cursor: pointer;
}

.aaaaaaa{
  /* padding: 16px; */
  width: 100vw;
  margin-top: 50px;
  /* height: calc(100vh - 130px); */
  
}

.footer{
  width: 100%;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
}

.inputCheckbox{
  width: 16px;
  height: 16px;
}

.res{
  font-size: 20px;
}
.btn{
  /* width: 80px;
  height: 30px; */
  font-size: 16px;
}
</style> -->